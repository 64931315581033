import React, { Component } from 'react'
import { withSnackbar } from 'notistack';
import Accordions from './Accordions';
import Service from "../../config/networkutils";
import { RotateCircleLoading } from 'react-loadingg';
import { getCookie } from '../../utils/CookieHelper';

const token = getCookie("ptd_ts_token");

class UserPermission extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            data: [],
            comData: {},
            resData: {},
        }

        this.rewriteData = this.rewriteData.bind(this)
    }

    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {
        Service.get(`/api/access/?uid=` + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                this.setState({
                    data: res.data,
                });

                this.compileData(res.data);
            })
            .catch(error => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            });
    }

    compileData(response) {
        var loca = {
            l0a: {
                l0a_a: false,
            },
            l1a: {
                l1a_a: false,
                l1a_b: false,
                l1a_c: false,
                l1a_d: false,
                l1a_e: false,
                l1a_f: false,
                l1a_g: false,
            },
            l2a: {
                l2a_a: false,
                l2a_b: false,
                l2a_c: false,
            },
            l3a: {
                l3a_a: false,
                l3a_b: false,
                l3a_c: false,
                l3a_d: false,
            },
            l4a: {
                l4a_a: false,
                l4a_b: false,
                l4a_c: false,
                l4a_d: false,
            },
            l5a: {
                l5a_a: false,
                l5a_b: false,
                l5a_c: false,
                l5a_d: false,
                l5a_e: false,
            },
            l6a: {
                l6a_a: false,
                l6a_b: false,
                l6a_c: false,
                l6a_d: false,
                l6a_e: false,
            },
            l7a: {
                l7a_a: false,
            },
            l8a: {
                l8a_a: false,
                l8a_b: false,
                l8a_c: false,
            },
            l9a: {
                l9a_a: false,
                l9a_b: false,
            },
            l10a:{
                l10a_a: false,
                l10a_b: false,
            },
            l11a:{
                l11a_a: false,
                l11a_b: false,
            },
            l12a:{
                l12a_a: false,
            }
        };
        var data = {
            l0a: {
                left: [],
                right: [],
            },
            l1a: {
                left: [],
                right: [],
            },
            l2a: {
                left: [],
                right: [],
            },
            l3a: {
                left: [],
                right: [],
            },
            l4a: {
                left: [],
                right: [],
            },
            l5a: {
                left: [],
                right: [],
            },
            l6a: {
                left: [],
                right: [],
            },
            l7a: {
                left: [],
                right: [],
            },
            l8a: {
                left: [],
                right: [],
            },
            l9a: {
                left: [],
                right: [],
            },
            l10a: {
                left: [],
                right: [],
            },
            l11a: {
                left: [],
                right: [],
            },
            l12a: {
                left: [],
                right: [],
            },
        };

        if (response.length !== 0) {
            loca = response[0].access;
        }

        if (!loca.l0a) {
            loca.l0a = {}
        }
        if (!loca.l1a) {
            loca.l1a = {}
        }
        if (!loca.l2a) {
            loca.l2a = {}
        }
        if (!loca.l3a) {
            loca.l3a = {}
        }
        if (!loca.l4a) {
            loca.l4a = {}
        }
        if (!loca.l5a) {
            loca.l5a = {}
        }
        if (!loca.l6a) {
            loca.l6a = {}
        }
        if (!loca.l7a) {
            loca.l7a = {}
        }
        if (!loca.l8a) {
            loca.l8a = {}
        }
        if (!loca.l9a) {
            loca.l9a = {}
        }
        if (!loca.l10a) {
            loca.l10a = {}
        }
        if (!loca.l11a) {
            loca.l11a = {}
        }
        if (!loca.l12a) {
            loca.l12a = {}
        }


        if (loca.l0a && loca.l0a.l0a_a) {
            data.l0a.right.push({
                id: 'l0a_a',
                name: 'Basic PTDRM Access'
            })
        } else if (loca.l0a) {
            data.l0a.left.push({
                id: 'l0a_a',
                name: 'Basic PTDRM Access'
            })
        }



        if (loca.l1a && loca.l1a.l1a_a) {
            data.l1a.right.push({
                id: 'l1a_a',
                name: 'View CRM Data'
            })
        } else if (loca.l1a) {
            data.l1a.left.push({
                id: 'l1a_a',
                name: 'View CRM Data'
            })
        }
        if (loca.l1a && loca.l1a.l1a_b) {
            data.l1a.right.push({
                id: 'l1a_b',
                name: 'Add CRM Data'
            })
        } else if (loca.l1a) {
            data.l1a.left.push({
                id: 'l1a_b',
                name: 'Add CRM Data'
            })
        }

        if (loca.l1a && loca.l1a.l1a_c) {
            data.l1a.right.push({
                id: 'l1a_c',
                name: 'Edit CRM Data'
            })
        } else if (loca.l1a) {
            data.l1a.left.push({
                id: 'l1a_c',
                name: 'Edit CRM Data'
            })
        }

        if (loca.l1a && loca.l1a.l1a_d) {
            data.l1a.right.push({
                id: 'l1a_d',
                name: 'Bulk Upload CRM Data'
            })
        } else if (loca.l1a) {
            data.l1a.left.push({
                id: 'l1a_d',
                name: 'Bulk Upload CRM Data'
            })
        }

        if (loca.l1a && loca.l1a.l1a_e) {
            data.l1a.right.push({
                id: 'l1a_e',
                name: 'View BID Resource Form'
            })
        } else if (loca.l1a) {
            data.l1a.left.push({
                id: 'l1a_e',
                name: 'View BID Resource Form'
            })
        }

        if (loca.l1a && loca.l1a.l1a_f) {
            data.l1a.right.push({
                id: 'l1a_f',
                name: 'Edit BID Resource Form'
            })
        } else if (loca.l1a) {
            data.l1a.left.push({
                id: 'l1a_f',
                name: 'Edit BID Resource Form'
            })
        }

        if (loca.l1a && loca.l1a.l1a_g) {
            data.l1a.right.push({
                id: 'l1a_g',
                name: 'View Site Schedule'
            })
        } else if (loca.l1a) {
            data.l1a.left.push({
                id: 'l1a_g',
                name: 'View Site Schedule'
            })
        }

        if (loca.l2a && loca.l2a.l2a_a) {
            data.l2a.right.push({
                id: 'l2a_a',
                name: 'View Input Data'
            })
        } else if (loca.l2a) {
            data.l2a.left.push({
                id: 'l2a_a',
                name: 'View Input Data'
            })
        }

        if (loca.l2a && loca.l2a.l2a_b) {
            data.l2a.right.push({
                id: 'l2a_b',
                name: 'Add Input Data'
            })
        } else if (loca.l2a) {
            data.l2a.left.push({
                id: 'l2a_b',
                name: 'Add Input Data'
            })
        }

        if (loca.l2a && loca.l2a.l2a_c) {
            data.l2a.right.push({
                id: 'l2a_c',
                name: 'Edit Input Data'
            })
        } else if (loca.l2a) {
            data.l2a.left.push({
                id: 'l2a_c',
                name: 'Edit Input Data'
            })
        }


        if (loca.l3a && loca.l3a.l3a_a) {
            data.l3a.right.push({
                id: 'l3a_a',
                name: 'View Role'
            })
        } else if (loca.l3a) {
            data.l3a.left.push({
                id: 'l3a_a',
                name: 'View Role'
            })
        }

        if (loca.l3a && loca.l3a.l3a_b) {
            data.l3a.right.push({
                id: 'l3a_b',
                name: 'Add Role'
            })
        } else if (loca.l3a) {
            data.l3a.left.push({
                id: 'l3a_b',
                name: 'Add Role'
            })
        }

        if (loca.l3a && loca.l3a.l3a_c) {
            data.l3a.right.push({
                id: 'l3a_c',
                name: 'Edit Role'
            })
        } else if (loca.l3a) {
            data.l3a.left.push({
                id: 'l3a_c',
                name: 'Edit Role'
            })
        }

        if (loca.l3a && loca.l3a.l3a_d) {
            data.l3a.right.push({
                id: 'l3a_d',
                name: 'Bulk Upload Roles'
            })
        } else if (loca.l3a) {
            data.l3a.left.push({
                id: 'l3a_d',
                name: 'Bulk Upload Roles'
            })
        }

        if (loca.l4a && loca.l4a.l4a_a) {
            data.l4a.right.push({
                id: 'l4a_a',
                name: 'View Employees'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_a',
                name: 'View Employees'
            })
        }

        if (loca.l4a && loca.l4a.l4a_b) {
            data.l4a.right.push({
                id: 'l4a_b',
                name: 'Add Employee'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_b',
                name: 'Add Employee'
            })
        }

        if (loca.l4a && loca.l4a.l4a_c) {
            data.l4a.right.push({
                id: 'l4a_c',
                name: 'Edit Employee'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_c',
                name: 'Edit Employee'
            })
        }

        if (loca.l4a && loca.l4a.l4a_d) {
            data.l4a.right.push({
                id: 'l4a_d',
                name: 'Bulk Upload Employees'
            })
        } else if (loca.l4a) {
            data.l4a.left.push({
                id: 'l4a_d',
                name: 'Bulk Upload Employees'
            })
        }


        if (loca.l5a && loca.l5a.l5a_a) {
            data.l5a.right.push({
                id: 'l5a_a',
                name: 'View Unsecured Opportunity'
            })
        } else if (loca.l5a) {
            data.l5a.left.push({
                id: 'l5a_a',
                name: 'View Unsecured Opportunity'
            })
        }

        if (loca.l5a && loca.l5a.l5a_b) {
            data.l5a.right.push({
                id: 'l5a_b',
                name: 'Add Unsecured PRP Data'
            })
        } else if (loca.l5a) {
            data.l5a.left.push({
                id: 'l5a_b',
                name: 'Add Unsecured PRP Data'
            })
        }

        if (loca.l5a && loca.l5a.l5a_c) {
            data.l5a.right.push({
                id: 'l5a_c',
                name: 'Edit Unsecured PRP Data'
            })
        } else if (loca.l5a) {
            data.l5a.left.push({
                id: 'l5a_c',
                name: 'Edit Unsecured PRP Data'
            })
        }

        if (loca.l5a && loca.l5a.l5a_d) {
            data.l5a.right.push({
                id: 'l5a_d',
                name: 'Bulk Upload Unsecured PRP Data'
            })
        } else if (loca.l5a) {
            data.l5a.left.push({
                id: 'l5a_d',
                name: 'Bulk Upload Unsecured PRP Data'
            })
        }
        if (loca.l5a && loca.l5a.l5a_e) {
            data.l5a.right.push({
                id: 'l5a_e',
                name: 'Delete PRP Data'
            })
        } else if (loca.l5a) {
            data.l5a.left.push({
                id: 'l5a_e',
                name: 'Delete PRP Data'
            })
        }

        if (loca.l6a && loca.l6a.l6a_a) {
            data.l6a.right.push({
                id: 'l6a_a',
                name: 'View Secured Opportunity'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_a',
                name: 'View Secured Opportunity '
            })
        }

        if (loca.l6a && loca.l6a.l6a_b) {
            data.l6a.right.push({
                id: 'l6a_b',
                name: 'Add Secured PRP Data'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_b',
                name: 'Add Secured PRP Data'
            })
        }

        if (loca.l6a && loca.l6a.l6a_c) {
            data.l6a.right.push({
                id: 'l6a_c',
                name: 'Edit Secured PRP Data'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_c',
                name: 'Edit Secured PRP Data'
            })
        }

        if (loca.l6a && loca.l6a.l6a_d) {
            data.l6a.right.push({
                id: 'l6a_d',
                name: 'Bulk Upload Secured PRP Data'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_d',
                name: 'Bulk Upload Secured PRP Data'
            })
        }
        if (loca.l6a && loca.l6a.l6a_e) {
            data.l6a.right.push({
                id: 'l6a_e',
                name: 'Delete PRP Data'
            })
        } else if (loca.l6a) {
            data.l6a.left.push({
                id: 'l6a_e',
                name: 'Delete PRP Data'
            })
        }



        if (loca.l7a && loca.l7a.l7a_a) {
            data.l7a.right.push({
                id: 'l7a_a',
                name: 'View Reports'
            })
        } else if (loca.l7a) {
            data.l7a.left.push({
                id: 'l7a_a',
                name: 'View Reports'
            })
        }

        // if (loca.l8a && loca.l8a.l8a_a) {
        //     data.l8a.right.push({
        //         id: 'l8a_a',
        //         name: 'View Users & Access'
        //     })
        // } else if (loca.l8a) {
        //     data.l8a.left.push({
        //         id: 'l8a_a',
        //         name: 'View Users & Access'
        //     })
        // }

        // if (loca.l8a && loca.l8a.l8a_b) {
        //     data.l8a.right.push({
        //         id: 'l8a_b',
        //         name: 'Add Users & Access'
        //     })
        // } else if (loca.l8a) {
        //     data.l8a.left.push({
        //         id: 'l8a_b',
        //         name: 'Add Users & Access'
        //     })
        // }

        // if (loca.l8a && loca.l8a.l8a_c) {
        //     data.l8a.right.push({
        //         id: 'l8a_c',
        //         name: 'Edit Users & Access'
        //     })
        // } else if (loca.l8a) {
        //     data.l8a.left.push({
        //         id: 'l8a_c',
        //         name: 'Edit Users & Access'
        //     })
        // }

        if (loca.l9a && loca.l9a.l9a_a) {
            data.l9a.right.push({
                id: 'l9a_a',
                name: 'View Resource Profile'
            })
        } else if (loca.l9a) {
            data.l9a.left.push({
                id: 'l9a_a',
                name: 'View Resource Profile'
            })
        }

        if (loca.l9a && loca.l9a.l9a_b) {
            data.l9a.right.push({
                id: 'l9a_b',
                name: 'Edit Resource Profile'
            })
        } else if (loca.l9a) {
            data.l9a.left.push({
                id: 'l9a_b',
                name: 'Edit Resouce Profile'
            })
        }

        if (loca.l10a && loca.l10a.l10a_a) {
            data.l10a.right.push({
                id: 'l10a_a',
                name: 'View Reporting Instructions'
            })
        } else if (loca.l10a) {
            data.l10a.left.push({
                id: 'l10a_a',
                name: 'View Reporting Instructions'
            })
        }

        if (loca.l10a && loca.l10a.l10a_b) {
            data.l10a.right.push({
                id: 'l10a_b',
                name: 'View Email Reporting Instructions Button '
            })
        } else if (loca.l10a) {
            data.l10a.left.push({
                id: 'l10a_b',
                name: 'View Email Reporting Instructions Button '
            })
        }

        if (loca.l11a && loca.l11a.l11a_a) {
            data.l11a.right.push({
                id: 'l11a_a',
                name: 'Admin Access'
            })
        } else if (loca.l11a) {
            data.l11a.left.push({
                id: 'l11a_a',
                name: 'Admin Access'
            })
        }

        // if (loca.l11a && loca.l11a.l11a_b) {
        //     data.l11a.right.push({
        //         id: 'l11a_b',
        //         name: 'Line Manager Access'
        //     })
        // } else if (loca.l11a) {
        //     data.l11a.left.push({
        //         id: 'l11a_b',
        //         name: 'Line Manager Access'
        //     })
        // }

        if(loca.l12a && loca.l12a.l12a_a){
            data.l12a.right.push({
                id: 'l12a_a',
                name: 'View SubContractor'
            })
        }else if(loca.l12a){
            data.l12a.left.push({
                id: 'l12a_a',
                name: 'View SubContractor'
            })
        }


        this.setState({ comData: data, isLoading: false, resData: loca })

    }

    rewriteData(data, loca, response, userData) {

        if (data.l0a) {
            for (let i = 0; i < data.l0a.left.length; i++) {
                const element = data.l0a.left[i];
                loca.l0a[element.id] = false;
            }
            for (let i = 0; i < data.l0a.right.length; i++) {
                const element = data.l0a.right[i];
                loca.l0a[element.id] = true;
            }
        }

        if (data.l1a) {
            for (let i = 0; i < data.l1a.left.length; i++) {
                const element = data.l1a.left[i];
                loca.l1a[element.id] = false;
            }
            for (let i = 0; i < data.l1a.right.length; i++) {
                const element = data.l1a.right[i];
                loca.l1a[element.id] = true;
            }
        }

        if (data.l2a) {
            for (let i = 0; i < data.l2a.left.length; i++) {
                const element = data.l2a.left[i];
                loca.l2a[element.id] = false;
            }
            for (let i = 0; i < data.l2a.right.length; i++) {
                const element = data.l2a.right[i];
                loca.l2a[element.id] = true;
            }
        }

        if (data.l3a) {
            for (let i = 0; i < data.l3a.left.length; i++) {
                const element = data.l3a.left[i];
                loca.l3a[element.id] = false;
            }
            for (let i = 0; i < data.l3a.right.length; i++) {
                const element = data.l3a.right[i];
                loca.l3a[element.id] = true;
            }
        }

        if (data.l4a) {
            for (let i = 0; i < data.l4a.left.length; i++) {
                const element = data.l4a.left[i];
                loca.l4a[element.id] = false;
            }
            for (let i = 0; i < data.l4a.right.length; i++) {
                const element = data.l4a.right[i];
                loca.l4a[element.id] = true;
            }
        }

        if (data.l5a) {
            for (let i = 0; i < data.l5a.left.length; i++) {
                const element = data.l5a.left[i];
                loca.l5a[element.id] = false;
            }
            for (let i = 0; i < data.l5a.right.length; i++) {
                const element = data.l5a.right[i];
                loca.l5a[element.id] = true;
            }
        }

        if (data.l6a) {
            for (let i = 0; i < data.l6a.left.length; i++) {
                const element = data.l6a.left[i];
                loca.l6a[element.id] = false;
            }
            for (let i = 0; i < data.l6a.right.length; i++) {
                const element = data.l6a.right[i];
                loca.l6a[element.id] = true;
            }
        }

        if (data.l7a) {
            for (let i = 0; i < data.l7a.left.length; i++) {
                const element = data.l7a.left[i];
                loca.l7a[element.id] = false;
            }
            for (let i = 0; i < data.l7a.right.length; i++) {
                const element = data.l7a.right[i];
                loca.l7a[element.id] = true;
            }
        }

        if (data.l8a) {
            for (let i = 0; i < data.l8a.left.length; i++) {
                const element = data.l8a.left[i];
                loca.l8a[element.id] = false;
            }
            for (let i = 0; i < data.l8a.right.length; i++) {
                const element = data.l8a.right[i];
                loca.l8a[element.id] = true;
            }
        }

        if (data.l9a) {
            for (let i = 0; i < data.l9a.left.length; i++) {
                const element = data.l9a.left[i];
                loca.l9a[element.id] = false;
            }
            for (let i = 0; i < data.l9a.right.length; i++) {
                const element = data.l9a.right[i];
                loca.l9a[element.id] = true;
            }
        }

        if (data.l10a) {
            for (let i = 0; i < data.l10a.left.length; i++) {
                const element = data.l10a.left[i];
                loca.l10a[element.id] = false;
            }
            for (let i = 0; i < data.l10a.right.length; i++) {
                const element = data.l10a.right[i];
                loca.l10a[element.id] = true;
            }
        }

        if (data.l11a) {
            for (let i = 0; i < data.l11a.left.length; i++) {
                const element = data.l11a.left[i];
                loca.l11a[element.id] = false;
            }
            for (let i = 0; i < data.l11a.right.length; i++) {
                const element = data.l11a.right[i];
                loca.l11a[element.id] = true;
            }
        }

        if (data.l12a) {
            for (let i = 0; i < data.l12a.left.length; i++) {
                const element = data.l12a.left[i];
                loca.l12a[element.id] = false;
            }
            for (let i = 0; i < data.l12a.right.length; i++) {
                const element = data.l12a.right[i];
                loca.l12a[element.id] = true;
            }
        }


        var dataTo = {
            uid: userData.id,
            access: loca,
            last_updated: new Date(),
        }

        if (response.length === 0) {
            this.setState({ isLoading: true })
            Service.post(`/api/access/`, {
                headers: {
                    Authorization: "Token " + token,
                },
                data: dataTo,
            })
                .then(res => {
                    
                    this.props.enqueueSnackbar('Change saved!', {
                        variant: 'success'
                    });
                    this.fetchOps();
                })
                .catch(error => {
                    this.setState({ isLoading: false })
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                });
        } else {
            Service.put(`/api/access/` + response[0].id +"/", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: dataTo,
            })
                .then(res => {
                    this.props.enqueueSnackbar('Change saved!', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                });
        }

    }

    render() {
        return (
            <div>
                {(this.state.isLoading) ? (
                    <RotateCircleLoading color="#005D99" />
                ) : (
                    <div>
                        {/* <Box bgcolor="grey.300">
                                <Button onClick={() => { this.rewriteData() }} >save</Button>
                            </Box> */}
                        {/* <Box p={3} bgcolor="grey.300"> */}
                        <Accordions
                            value={this.state.comData}
                            resData={this.state.resData}
                            user={this.props.value}
                            data={this.state.data}
                            handleToUpdate={this.rewriteData}
                            editAccess={this.props.edit} />
                        {/* </Box> */}
                    </div>
                )}
            </div>
        )
    }
}


export default withSnackbar(UserPermission);