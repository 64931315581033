import React from 'react';
import MaterialTable from 'material-table';
import moment from 'moment';
import { weekStartDates } from '../../utils/betweenDates';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcom from '@material-ui/icons/RemoveCircle'
import { Button, Grid, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, IconButton, Popover, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { prp_roles_api_bulk_update, prp_roles_api, roles_sectors_regions } from '../../config/apiList';
import { getCookie } from '../../utils/CookieHelper';
import Service from '../../config/networkutils';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';

const token = getCookie("ptd_ts_token");
const validQty = [0.2, 0.4, 0.5, 0.6, 0.8, 1]

const excelColumns = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ', 'BA', 'BB', 'BC', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BK'];

const useStyles = (theme) => ({
    // appBar: {
    //     position: 'fixed',
    //     top: 0,
    // },
    formContainer: {
        marginTop: '64px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    date: {
        margin: 0,
        width: '100%',
        'margin-top': '5px'
    },
});

class ResourceTable extends React.Component {
    constructor() {
        super()
        this.state = {
            columns: [],
            data: [],
            selectedOpportunity: [],
            selectedSalesStage: [],
            selectedRoles: [],
            tableDialog: false,
            customQtyValues: [],
            openClearConfirm: false,
            dataToClear: {},
            deletedQty: {},
            unallocateDeleteDialog: false
        }
    }

    componentDidMount() {

        this.getData()

    }

    getData() {
        let { columns, data, selectedOpportunity, selectedSalesStage, selectedRoles, tableDialog, selectedTimeDuration } = this.props
        this.setState({ columns, data, selectedOpportunity, selectedSalesStage, selectedRoles, tableDialog, selectedTimeDuration }, () => this.groupByRoles())
    }


    groupByRoles() {
        let resources = [...this.state.data]
        let groupedResourcesbyRole = {};
        let groupedRoles = [];

        resources.forEach((resource, resourceINd) => {
            if (groupedResourcesbyRole.hasOwnProperty(resource.role_id)) {
                groupedResourcesbyRole[resource.role_id].push(resource)
            } else {
                groupedResourcesbyRole = { ...groupedResourcesbyRole, [resource.role_id]: [resource] }
                groupedRoles.push({ role_id: [resource.role_id], role_name: [resource.role_name] })
            }
        });

        this.setState({ groupedResourcesbyRole, groupedRoles })
    }

    viewReport = () => {
        // let api = '/api/prp/role/global_edit/'
        let crms = []; this.state.selectedOpportunity.forEach(ele => { if (ele.id !== 0) crms.push(ele.id) })
        // if (crms.length) api = api + this.getSymbol(api) + 'crm=' + crms.join(',')

        let roles = []; this.state.selectedRoles.filter(ele => roles.push(ele.id))
        // if (roles.length) api = api + this.getSymbol(api) + 'role=' + roles.join(',')

        let status = []; this.state.selectedSalesStage.filter(ele => status.push(ele.id))
        // if (status.length) api = api + this.getSymbol(api) + 'status=' + status.join(',')

        let roles_crm_emp_uri = roles_sectors_regions + "?archive_reason=0,1,2&";
        if (roles.length > 0) {
            roles_crm_emp_uri = roles_crm_emp_uri + `role=${roles.join()}&`
        }
        if (crms.length > 0) {
            roles_crm_emp_uri = roles_crm_emp_uri + `crm=${crms.join()}&`
        }
        if (status.length > 0) {
            roles_crm_emp_uri = roles_crm_emp_uri + `status=${status.join()}&`
        }
        if (roles_crm_emp_uri[roles_crm_emp_uri.length - 1] === "&") {
            roles_crm_emp_uri = roles_crm_emp_uri.substring(0, roles_crm_emp_uri.length - 1)
        }

        Service.get(roles_crm_emp_uri,
            {
                headers: {
                    Authorization: "Token " + token,
                },
            })
            .then(res => {
                this.parseResData(res.data)
            })
            .catch(err => {

                this.setState({ loading: false })
            })
    }

    customCellcolor(rowData) {
        if (rowData.crm_status === "Closed/Won") {
            return { backgroundColor: '#9CCC65' };
        }
        if (rowData.crm_status === "Closed/Lost") {
            return { backgroundColor: '#F44336' };
        }
        if (rowData.crm_status === "Prospect") {
            return { backgroundColor: '#F8BBD0' };
        }
        if (
            rowData.crm_status === "Confirmed Invite" ||
            rowData.crm_status === "Lead" ||
            rowData.crm_status === "PQQ" ||
            rowData.crm_status === "Preferred Bidder" ||
            rowData.crm_status === "Tender"
        ) {
            return { backgroundColor: '#FFE57F' };
        }

        return {};
    }

    parseResData(data) {
        let parsedData = []
        let all_dates = []
        for (let i = 0; i < data.length; i++) {
            let obj = data[i];
            let dates = data[i].dates;
            for (const date in dates) {
                if (all_dates.includes(date)) { }
                else { all_dates.push(date); obj = { ...obj, [date]: null } }
            }
            obj = { ...obj, ...dates }
            parsedData.push(obj)
        }
        let sorted = all_dates.sort(function (a, b) {
            a = a.toString().split('/');
            b = b.toString().split('/');
            return a[2] - b[2] || a[1] - b[1] || a[0] - b[0];
        });
        this.setState({ sortedDates: sorted })
        let columns = []

        columns.push({
            title: 'Project Number', field: 'crm_opportunity_id', width: 100, editable: 'never',
            cellStyle: (cellData, rowData) => this.customCellcolor(rowData)
        });
        columns.push({
            title: 'Project Name', field: 'crm_name', width: 200, editable: 'never',
            cellStyle: (cellData, rowData) => this.customCellcolor(rowData)
        })
        if (this.state.selectedRoles.length !== 1) {
            columns.push({
                title: 'Role', field: 'role_name', width: 150, editable: 'never',
                cellStyle: (cellData, rowData) => this.customCellcolor(rowData)
            })
        }
        columns.push({
            title: 'Resource', width: 100,
            editable: 'never',
            field: 'emp_full_name',
            cellStyle: (cellData, rowData) => {
                return {
                    backgroundColor: '#CFD8DC'
                }
            }
        })
        columns.push({
            title: 'Stage', field: 'crm_status', width: 100, editable: 'never',
        })
        let startdate = moment().format('DD/MM/YYYY')
        let endDate = moment(startdate, "DD/MM/YYYY").add(this.state.selectedTimeDuration.duration, 'months').format('DD/MM/YYYY');
        sorted.forEach(date => {
            if (moment(date, 'DD/MM/YYYY').isAfter(moment(startdate, 'DD/MM/YYYY')) && moment(date, 'DD/MM/YYYY').isBefore(moment(endDate, 'DD/MM/YYYY'))) {
                let newObj = {
                    title: date,
                    field: date,
                    type: 'number',
                    width: 70,
                    sorting: false,
                    headerStyle: {
                        transform: 'rotate(270deg)',
                        padding: '10px',
                        height: '100px'
                    },
                    cellStyle: {
                        textAlign: 'center'
                    },
                    filtering: false
                }
                columns.push(newObj)
            }

        })
        parsedData.sort(function (a, b) {
            if (a.crm_name < b.crm_name) { return -1; }
            if (a.crm_name > b.crm_name) { return 1; }
            return 0;
        })
        parsedData.forEach(rowData => {
            let emp_full_name = rowData.emp_first_name && rowData.emp_last_name ? rowData.emp_first_name + ' ' + rowData.emp_last_name : 'NA';
            rowData['emp_full_name'] = emp_full_name
        })

        this.setState({ columns: columns, data: this.sortProjectAndroles(parsedData) }, () => {
            this.setState({ loading: false })
        })
    }

    sortProjectAndroles(allRoles) {
        let allIds = []
        allRoles.forEach(ele => allIds.push(ele.crm));
        let distinctProjcts = [...new Set(allIds)];
        let allRolesSorted = []
        for (let i = 0; i < distinctProjcts.length; i++) {
            let roles = []
            for (let j = 0; j < allRoles.length; j++) {
                if (allRoles[j].crm === distinctProjcts[i]) {
                    roles.push(allRoles[j])
                }
            }
            allRolesSorted.push(...this.rolesOrderSort(roles))
        }
        return allRolesSorted
    }

    rolesOrderSort(combinedRoleData) {
        let sortOrder = [
            'Head of Operations',
            'Senior Project Manager',
            'Project Manager',
            'Project Engineer',
            'Site Manager',
            'Site Engineer',
            'General Foreman',
            'Linesmen_Gang 1',
            'Civil Supervisor',
            'Civil Operative'
        ]
        let rolesSorted = [];
        // For roles in sort order list
        for (let i = 0; i < sortOrder.length; i++) {
            for (let j = 0; j < combinedRoleData.length; j++) {
                if (combinedRoleData[j].role_name && combinedRoleData[j].role_name.includes(sortOrder[i])) {
                    rolesSorted.push(combinedRoleData[j])
                }
            }
        }

        // For other roles from order list 
        for (let i = 0; i < combinedRoleData.length; i++) {
            let matched = false
            for (let j = 0; j < sortOrder.length; j++) {
                if (combinedRoleData[i].role_name && combinedRoleData[i].role_name.includes(sortOrder[j])) {
                    matched = true;
                }
            }
            if (!matched) {
                rolesSorted.push(combinedRoleData[i])
            }
        }
        return rolesSorted;
    }

    bulkChanges(changes) {
        this.setState({ loading: true })
        let finalChanges = []
        for (const key in changes) {

            let data = { ...changes[key].newData }
            for (const key in data) {
                if (moment(key, 'DD/MM/YYYY').isValid()) {
                    data.dates = { ...data.dates, [key]: data[key] }
                }
            }
            finalChanges.push(data)
        }
        // data['dates'] = addedValues;
        this.bulkEmployeeUpdate(finalChanges)
    }

    bulkEmployeeUpdate(data) {
        Service.patch(prp_roles_api_bulk_update, {
            headers: { Authorization: "Token " + token, },
            data
        })
            .then(res => {

                this.props.enqueueSnackbar(res.data.length + (res.data.length === 1 ? ' Employee' : ' Employees') + ' successfully updated', {
                    variant: 'success'
                });
                this.viewReport()
                this.props.reload()
            })
            .catch(e => {
                console.error(e)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
    }

    rowClicked = (e, rowdata) => {
        this.setState({ selectedOpportunity: rowdata })
        this.handleClickOpen()
    }

    handleClickOpen = () => { this.setState({ modelOpen: true }) }

    handleClose = () => { this.setState({ modelOpen: false }) }

    handleChange = (event) => {
        this.setState({ selectedFilter: event.target.value });
    };

    getSymbol(api) {
        return api.includes('?') ? '&' : '?'
    }

    setOpportunity(e, value) {
        value.forEach(ele => {
            if (ele.name === "Select All") {
                // this.setState({selectedFilter : this.state.opportunity})
                value = this.state.opportunity
            }
        })
        this.setState({ selectedOpportunity: value });
    }

    openDialog = () => {
        this.setState({ showDialog: true })
    }

    closeDialog = () => {
        this.setState({ showDialog: false })
    }

    addCustomField = () => {
        let fileds = [...this.state.customQtyValues]
        let obj = {
            from: null,
            to: null,
            qty: '',
            error: false,
            errorMsg: ''
        }
        fileds.push(obj)
        this.setState({ customQtyValues: fileds })
    }

    removeCustomQty = (idx) => {
        let data = [...this.state.customQtyValues];
        data.splice(idx, 1);
        this.setState({ customQtyValues: data })
    }

    handleCustomQty = (e, idx) => {
        let preObj = { ...this.state.customQtyValues[idx] }
        preObj['qty'] = e.target.value;
        let newValues = [...this.state.customQtyValues];
        newValues[idx] = preObj;
        this.setState({ customQtyValues: newValues })
    }

    handleCustomToDate = (date, idx) => {
        let preObj = { ...this.state.customQtyValues[idx] }
        preObj['to'] = date;
        let newValues = [...this.state.customQtyValues];
        newValues[idx] = preObj;
        this.setState({ customQtyValues: newValues })
    }

    handleCustomFromDate = (date, idx) => {
        let preObj = { ...this.state.customQtyValues[idx] }
        preObj['from'] = date;
        let newValues = [...this.state.customQtyValues];
        newValues[idx] = preObj;
        this.setState({ customQtyValues: newValues })
    }

    applyCustomQty = () => {
        try {
            let allDatesQty = {}
            let continueToApply = true;
            this.state.customQtyValues.forEach((dates, i) => {
                let alldates = weekStartDates(dates.from, dates.to);
                alldates.forEach(date => {
                    let qty = Number(dates.qty)
                    if (!validQty.includes(qty)) {
                        dates.error = true;
                        dates.errorMsg = "Qty must be 0.2, 0.4, 0.5, 0.6, 0.8 or 1";
                        continueToApply = false
                    }
                    if (date in allDatesQty) {
                        qty += Number(allDatesQty[date])
                        if (qty > 1) {
                            dates.error = true;
                            dates.errorMsg = "Qty value can not be more than 1/week for " + date;
                            continueToApply = false
                        }
                    }
                    allDatesQty = { ...allDatesQty, [date]: qty.toFixed(1) }
                })
            })

            this.setState({ customQtyValues: this.state.customQtyValues })

            if (!continueToApply) return;

            this.setState({ loading: true })

            Service.put(prp_roles_api + this.state.selectedEmployee.id + "/?is_archive=0", {
                headers: {
                    Authorization: "Token " + token,
                },
                data: {
                    shdl_data: this.state.customQtyValues,
                    employee: this.state.selectedEmployee.employee,
                    dates: allDatesQty,
                    // type: this.state.type
                },
            })
                .then(res => {
                    this.props.enqueueSnackbar('Qty added successfully', {
                        variant: 'success'
                    });
                    this.props.reload()
                    this.closeDialog();
                    this.viewReport()

                })
                .catch(error => {

                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                })
                .finally(e => {
                    this.setState({ loading: false })
                });
        }
        catch (e) {

        }

    }

    handleClearConfirm(data) {
        this.setState({ openClearConfirm: true, dataToClear: data })
    }

    clearRow() {
        this.setState({ loading: true, openClearConfirm: false })

        Service.put(prp_roles_api + this.state.dataToClear.id + "/?is_archive=0", {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                shdl_data: [],
                employee: this.state.dataToClear.employee,
                dates: null,
                // type: this.state.type
            },
        })
            .then(res => {
                this.props.enqueueSnackbar('Row cleared successfully', {
                    variant: 'success'
                });
                this.viewReport()
            })
            .catch(error => {

                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
            })
            .finally(e => {
                this.setState({ loading: false })
            });
    }

    s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }

    exportCsv() {
        let data = [...this.state.data]
        let dates = [...this.props.sortedDates]

        var sheets = ["Report"]

        var sheetData = {}

        var exportData = {}

        sheetData["Report"] = {
            '!ref': "A1:" + excelColumns[dates.length + 5] + (data.length + 1),
            '!cols': [
                { wch: 15 },
                { wch: 30 },
                { wch: 20 },
                { wch: 15 },
                { wch: 10 },
            ],
            'A1': { t: "s", v: "Project Number" },
            'B1': { t: "s", v: "Project Name" },
            'C1': { t: "s", v: "Role" },
            'D1': { t: "s", v: "Resource" },
            'E1': { t: "s", v: "Stage" },
        }

        let colHeader = {}

        for (let i = 0; i < dates.length; i++) {
            colHeader = { ...colHeader, [excelColumns[i + 5] + '1']: { t: 's', v: dates[i] } }
        }

        let sheet = sheetData.Report
        try {
            for (let i = 0; i < data.length; i++) {
                let element = data[i]
                let Objdates = element.dates;
                sheet["A" + (i + 2)] = { t: "s", v: element.crm_opportunity_id ? element.crm_opportunity_id : '' }
                sheet["B" + (i + 2)] = { t: "s", v: element.crm_name ? element.crm_name : '' }
                sheet["C" + (i + 2)] = { t: "s", v: element.role_name ? element.role_name : '' }
                sheet["D" + (i + 2)] = { t: "s", v: element.emp_first_name ? (element.emp_first_name + ' ' + element.emp_last_name) : '' }
                sheet["E" + (i + 2)] = { t: "s", v: element.crm_status ? element.crm_status : '' }
                if (Objdates) {
                    for (let j = 0; j < dates.length; j++) {
                        Object.entries(Objdates).forEach(([key, value], index) => {
                            if (dates[j] === key) {
                                sheet[excelColumns[j + 5] + (i + 2)] = { t: "s", v: value }
                            }
                        });
                    }
                }
            }
            sheetData.Report = { ...sheetData.Report, ...sheet }
        }
        catch (e) {

        }
        sheetData.Report = { ...sheetData.Report, ...colHeader }
        exportData = {
            'SheetNames': sheets,
            'Sheets': sheetData
        }

        var blob = new Blob([this.s2ab(XLSX.write(exportData, { bookType: 'xlsx', type: 'binary' }))], { type: "application/vnd.ms-excel" });
        saveAs(blob, "Combined resource Report.xlsx")
    }

    deleteOrUnallocateSchedules(method) {
        Service.delete(`/api/role/schedule/delete_shdl_data/?id=` + this.state.deletedQty.id + '&method=' + method, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then((res) => {
                this.props.enqueueSnackbar('Schedule ' + method + " ed successfully", {
                    variant: "success",
                });
                this.setState({ unallocateDeleteDialog: false });
                this.applySingleCustomQty()
            })
            .catch((error) => {
                console.log(error)
                this.setState({ unallocateDeleteDialog: false })
                this.props.enqueueSnackbar("Something went wrong!", {
                    variant: "error",
                });
            })
    }

    render() {
        const access = this.props.access
        const handleCloseConfirm = () => {
            this.setState({ openClearConfirm: false })
        }
        return (
            <div>
                <div style={{
                    // zoom: 0.8,
                    marginTop: '65px'
                }}>
                    <MaterialTable
                        columns={[{ title: 'Roles', field: 'role_name', }]}
                        data={this.state.groupedRoles}
                        title="Combined Resource Profile"
                        isLoading={this.state.loading}
                        options={{
                            paging: false,
                        }}
                        actions={access.l9a && access.l9a.l9a_b ? [
                            {
                                icon: 'download',
                                tooltip: 'Download File',
                                isFreeAction: true,
                                onClick: (event, rowData) => {
                                    this.exportCsv()
                                }
                            },


                        ] : []}
                        detailPanel={rowData =>
                            <div style={{
                                width: '95vw',
                                overflowX: 'scroll'
                            }}>
                                <MaterialTable
                                    columns={this.state.columns}
                                    data={this.state.groupedResourcesbyRole[rowData.role_id]}
                                    title="Combined Resource Profile"
                                    options={{
                                        paging: false,
                                        search: false,
                                        fixedColumns: {
                                            left: this.state.selectedRoles.length !== 1 ? 5 : 4,
                                            right: 0
                                        },
                                        filtering: true,
                                        showTitle: false,
                                        searchFieldAlignment: 'left',
                                        // headerStyle: { position: 'sticky', top: 0 },
                                        // maxBodyHeight: '650px'
                                    }}
                                    isLoading={this.state.loading}
                                    editable={{
                                        onBulkUpdate: access.l9a && access.l9a.l9a_b ? changes =>
                                            new Promise((resolve, reject) => {
                                                setTimeout(() => {
                                                    this.bulkChanges(changes)
                                                    resolve();
                                                }, 1000);
                                            }) : undefined,
                                    }}
                                    actions={access.l9a && access.l9a.l9a_b ? [
                                        {
                                            icon: 'call_split',
                                            tooltip: 'Apply dates',
                                            onClick: (event, rowData) => {
                                                // Do save operation
                                                if (rowData.shdl_data.length === 0) {
                                                    this.addCustomField()
                                                }
                                                else {
                                                    this.setState({ customQtyValues: rowData.shdl_data, })
                                                }
                                                this.setState({ selectedEmployee: rowData, showDialog: true })
                                            }
                                        },
                                        {
                                            icon: 'clear',
                                            tooltip: 'Clear',
                                            onClick: (event, rowData) => {
                                                // Do save operation
                                                this.handleClearConfirm(rowData)
                                            }
                                        },
                                    ] : []}

                                />
                            </div>
                        }
                    />

                </div>

                {this.state.showDialog ?
                    <Dialog open={this.state.showDialog} onClose={this.closeDialog} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">
                            Add Qty
                            <IconButton aria-label="close" onClick={this.addCustomField} style={{ position: 'absolute', top: '5px', right: '20px' }}>
                                <AddIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {this.state.selectedEmployee.emp_id ? this.state.selectedEmployee.emp_first_name + ' ' + this.state.selectedEmployee.emp_last_name : ''}
                                &nbsp;({this.state.selectedEmployee.role_name})
                            </DialogContentText>
                            {this.state.customQtyValues.map((ele, i) => (
                                <Grid container spacing={3}>
                                    <Grid item xs={2} sm={2}>
                                        {/* <IconButton aria-label="remove"
                                            style={{ marginTop: '10px' }}
                                            // onClick={() => this.removeCustomQty(i)}
                                            onClick={() => this.setState({ deletedQty: ele, unallocateDeleteDialog: true })}
                                            disabled={this.state.customQtyValues.length === 1}>
                                            <RemoveIcom />
                                        </IconButton> */}
                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                            {(popupState) => (
                                                <div>
                                                    <IconButton aria-label="remove"
                                                        style={{ marginTop: '10px' }}  {...bindTrigger(popupState)}
                                                        // onClick={() => this.removeSingleCustomQty(i)}
                                                        // onClick={() => this.setState({ deletedQty: element, unallocateDeleteDialog: true })}
                                                        disabled={this.state?.singleCustomQtyValues.length === 1}
                                                    >
                                                        <RemoveIcom />
                                                    </IconButton>
                                                    <Popover
                                                        {...bindPopover(popupState)}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'center',
                                                        }}
                                                        onClick={() => this.setState({ deletedQty: ele, unallocateDeleteDialog: true })}
                                                    >
                                                        <MenuItem value={'unallocate'}>Unallocate</MenuItem>
                                                        <MenuItem value={'delete'}>Delete</MenuItem>
                                                    </Popover>
                                                </div>
                                            )}
                                        </PopupState>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id={'from'}
                                                label="From"
                                                // minDate={this.props.project.contractor_start_date}
                                                // maxDate={this.props.project.contractor_end_date}
                                                value={ele.from ? ele.from : null}
                                                onChange={(e) => this.handleCustomFromDate(e, i)}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                className={this.props.classes.date}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id={'to'}
                                                label="To"
                                                // minDate={this.props.project.contractor_start_date}
                                                // maxDate={this.props.project.contractor_end_date}
                                                value={ele.to ? ele.to : null}
                                                onChange={(e) => this.handleCustomToDate(e, i)}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                className={this.props.classes.date}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <TextField
                                            id={'qty'}
                                            label="Qty/Week"
                                            type="number"
                                            fullWidth
                                            margin="dense"
                                            value={ele.qty}
                                            onChange={(e) => this.handleCustomQty(e, i)}
                                        />
                                    </Grid>
                                    {ele.error === true ?
                                        <p style={{ marginTop: '0', marginLeft: '100px', color: '#F44336' }}
                                        >{ele.errorMsg}</p> : null}
                                </Grid>

                            ))}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.closeDialog} color="secondary">
                                Cancel
                            </Button>
                            <Button color="primary" onClick={this.applyCustomQty}>
                                Apply
                            </Button>
                        </DialogActions>
                    </Dialog>
                    : null}


                <div>
                    <Dialog
                        open={this.state.openClearConfirm}
                        onClose={handleCloseConfirm}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                This action will clear all dates data in selected row. Click proceed to continue.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseConfirm} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={() => this.clearRow()} color="primary" autoFocus>
                                Proceed
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.unallocateDeleteDialog} onClose={() => this.setState({ unallocateDeleteDialog: false })}>
                        <DialogTitle>Confirmation</DialogTitle>
                        <DialogContent>Do you want the following schedule to be unallocated or deleted?</DialogContent>
                        <DialogActions>
                            <Button color='primary' varaint='contained' onClick={() => this.deleteOrUnallocateSchedules('delete')}>Delete</Button>
                            <Button color='primary' varaint='contained' onClick={() => this.deleteOrUnallocateSchedules('unallocate')}>Unallocate</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        )
    }
}

export default (withSnackbar(withStyles(useStyles)(ResourceTable)))