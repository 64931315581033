import { Box, Button, CircularProgress, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { Component } from 'react'
import AllocateSchedulesforRoles from '../UserAllocation/AllocateSchedulesforRoles'
import { getCookie } from '../../utils/CookieHelper';
import Service from '../../config/networkutils';
import { withSnackbar } from 'notistack';

const token = getCookie("ptd_ts_token");

class AssignSubContractor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mainMenu: true,
            data: [],
            selectedSubcontractor: null,
            buttonDisabled: false
        }
        this.backToMainMenu = this.backToMainMenu.bind(this)
        this.refreshData = this.refreshData.bind(this) 
    }

    componentDidMount() {
        this.getSubContractor()
    }

    refreshData = () => {
        this.setState({ isLoading: true },() =>{
            this.fetchSchedule()
        })
    }

    

    getSubContractor = () => {
        Service.get('/api/subcontractor/company/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ data: res.data }, () => {
                    this.setState({ isLoading: false })
                })

            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                });
                this.setState({ buttonDisabled: false })
            });
    }

    fetchSchedule = () => {
        if (!this.state.selectedSubcontractor) {
            this.props.enqueueSnackbar("Please select a SubContractor", {
                variant: 'warning'
            });
            return
        }
        else {
            this.setState({ buttonDisabled: true })
            Service.get(`/api/prp/rolenew/get_unassigned_shdl/?crm=${this.props.crm.crm_id}&prp_temp=${this.props.user.id}`, {
                headers: {
                    Authorization: "Token " + token,
                },
            })
                .then(res => {
                    if (res.data.length === 0) {
                        this.props.enqueueSnackbar("No schedules available for this SubContractor", {
                            variant: 'warning'
                        });
                        this.setState({ buttonDisabled: false })
                        return
                    } else {
                        let data = res.data
                        for (let i = 0; i < data.length; i++) {
                            const element = data[i];
                            element.isSelected = false
                            for (let j = 0; j < element.shdl_data.length; j++) {
                                const element1 = element.shdl_data[j];
                                element1.isSelected = false
                                element1.updated_qty = element1.qty
                            }
                        }
                        this.setState({
                            schedules: data.sort((a, b) => b.shdl_data.length - a.shdl_data.length),
                            buttonDisabled: false,
                            mainMenu: false,
                            isLoading: false
                        }, () => {
                            if (this.props.increaseDialogSize) {
                                this.props.increaseDialogSize()
                            }
                            if ((this.state.schedules.length > 0) && (this.props.increaseDialogSize)) {

                            }
                        })
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.props.enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                    this.setState({ buttonDisabled: false })
                });
        }
    }

    tabChangeAlert = (value) => {
        this.props.tabChangeAlert(value)
    }


    backToMainMenu = () => {
        this.setState({ mainMenu: true })
        this.props.reduceDialogSize()
    }

 
    render() {
        return (
            <div>
                <div>
                    {this.state.mainMenu ?
                        <Box>
                            <Box width={"100%"} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={this.state.data}
                                    getOptionLabel={(option) => option.name}
                                    value={this.state.selectedSubcontractor}
                                    style={{ width: '350px' }}
                                    onChange={(e, value) => this.setState({ selectedSubcontractor: value })}
                                    renderInput={(params) => <TextField {...params} label="SubContractor" variant="outlined" />} />
                            </Box>
                            <Box width={"100%"} display={'flex'} justifyContent={'center'} alignItems={'center'} marginTop={1}>
                                <Button disabled={this.state.buttonDisabled} color='primary' variant='contained' onClick={() => this.fetchSchedule()}>
                                    Allocate
                                </Button>
                            </Box>
                        </Box> :
                        <Box>
                            {this.state.isLoading ? <Box width={"100%"} display={'flex'} justifyContent={'center'} alignItems={'center'}><CircularProgress /></Box> :
                                <AllocateSchedulesforRoles
                                    subcontractor={true}
                                    role={this.props.role}
                                    tabChangeAlert={this.tabChangeAlert}
                                    selectedRole={this.state.selectedSubcontractor}
                                    project={this.props.project}
                                    crm={this.props.crm}
                                    user={this.props.user}
                                    closeDialog={this.props.closeDialog}
                                    backToMainMenu={this.backToMainMenu}
                                    refreshData={this.refreshData}
                                    schedules={this.state.schedules}
                                    allocationData={this.props.allocationData}
                                />
                            }
                        </Box>}
                </div>
            </div>
        )
    }
}

export default withSnackbar(AssignSubContractor);
