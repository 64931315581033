import React from 'react'
import MaterialTable from 'material-table';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from '@material-ui/core/Button';
import { Grid, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Typography, Popover } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcom from '@material-ui/icons/RemoveCircle'
import Service from '../../../../config/networkutils';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { weekStartDates } from '../../../../utils/betweenDates';
import { prp_roles_api } from '../../../../config/apiList';
import { getCookie } from '../../../../utils/CookieHelper';
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import TimelineIcon from "@material-ui/icons/Timeline";
import Tooltip from "@material-ui/core/Tooltip";
import { LoopCircleLoading } from 'react-loadingg';
import { withSnackbar } from "notistack";
import { ArchiveOutlined } from '@material-ui/icons';
import { filterOneObjArrayByAnoterObjArray } from '../../../../utils/reuseFunctions';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';

const token = getCookie("ptd_ts_token");


const useStyle = (theme) => ({
  customGridStyle: {
    margin: 0,
    paddingRight: '8px',
    paddingLeft: '16px',
    marginBottom: '16px'
  },
  date: {
    margin: 0,
    width: '100%',
    'margin-top': '5px'
  },
  formControl: {
    margin: theme.spacing(1),
    width: 200,
    float: 'right'
  },
  rows: {
    backgroundColor: "#EDFAFF",
  },
  leftSticky: {
    zIndex: 99, position: "sticky",
    left: 0,
    background: "#F8F8F8",
    boxShadow: "1px 2px 1px grey"
  }
})

// const validQty = [0.2, 0.4, 0.5, 0.6, 0.8, 1]

class PRPEmpTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [],
      data: [],
      dataLength: 0,
      start_date: '',
      subTabledata: [],
      showDialog: false,
      customQtyValues: [{
        from: this.props.project.contractor_start_date,
        to: this.props.project.contractor_end_date,
        qty: ''
      }],
      selectedRole: {},
      selectedEmployee: {},
      isLoading: true,
      addEmpDialog: false,
      allEmpList: [],
      empColumns: [],
      owner_region: {},
      owner_delivery_unit: {},
      sector: {},
      assign_emp_data: {},
      allEmpObj: {},
      combiRoleEmployee: [],
      type: 1,
      archiveAlert: false,
      archive_emp_data: '',
      assignEmployees: [],
      gangRoleEmployees: [],
      deletedQty: {},
      unallocateDeleteDialog: false
    }
    // Date.prototype.addDays = function (days) {
    //     var date = new Date(this.valueOf());
    //     date.setDate(date.getDate() + days);
    //     return date;
    // }
  }

  addCustomField = () => {
    let fileds = [...this.state.customQtyValues]
    let obj = {
      from: this.props.project.contractor_start_date,
      to: this.props.project.contractor_end_date,
      qty: '',
      error: false,
      errorMsg: ''
    }
    fileds.push(obj)
    this.setState({ customQtyValues: fileds })
  }

  openDialog = () => {
    this.setState({ showDialog: true })
  }

  closeDialog = () => {
    this.setState({ showDialog: false })
  }


  addEmpDialogOpen = () => {
    let roleId;
    this.props.prpData.forEach(ele => {
      if (ele.id === this.state.assign_emp_data.prp) {
        roleId = ele.role
      }
    })
    let role = this.props.allRoles[roleId];
    let combiRole = ''


    // this.props.roles.forEach(role => {
    //     if(roleId === role.id){
    //         combiRole = role.current_combi_role
    //     }
    // })

    for (let i = 0; i < this.props.roles.length; i++) {
      if (role === this.props.roles[i].job_roles) {
        combiRole = this.props.roles[i].current_combi_role;
        break;
      }
    }

    let combinedCombiRoles = [];
    for (let i = 0; i < this.props.roles.length; i++) {
      if (combiRole === this.props.roles[i].current_combi_role) {
        combinedCombiRoles.push(this.props.roles[i].id)
      }
    }


    let combiRoleEmps = [];
    this.state.allEmpList.forEach(emp => {
      // filter combi role
      if (combinedCombiRoles.includes(emp.job_title)) {
        combiRoleEmps.push(emp)
      }
      if (emp.global_assign === 1) {
        combiRoleEmps.push(emp)
      }
    })

    this.setState({ combiRoleEmployee: combiRoleEmps }, () => {
      this.setState({ addEmpDialog: true })
    })

  }

  addEmpDialogClose = () => {
    this.setState({ addEmpDialog: false, prp_id: null })
  }

  componentDidMount() {

    let allEmp = [];
    allEmp = [...this.props.employees]

    const { gang } = this.props;
    let gangEmployees = filterOneObjArrayByAnoterObjArray(allEmp, gang, 'id', 'ptd_employee')
    let gangRoleEmployees = []
    if (gangEmployees) {
      gangRoleEmployees = gangEmployees.filter(emp => emp.job_title === this.props.roleData.role)
    }

    this.setState({
      gangRoleEmployees,
      allEmpList: allEmp,
      owner_delivery_unit: this.props.owner_delivery_unit,
      owner_region: this.props.owner_region,
      sector: this.props.sector
    }, () => {
      this.fetchOps();
      // let empColumn = [
      //     { title: 'Employee No', field: 'emp_no', },
      //     { title: 'First Name', field: 'first_name', },
      //     { title: 'Last Name', field: 'last_name', },
      //     { title: 'Email Address', field: 'email', },
      //     { title: 'Sub Division', field: 'owner_region', lookup: this.state.owner_region },
      //     { title: 'Operations Lead', field: 'owner_delivery_unit', lookup: this.state.owner_delivery_unit },
      //     { title: 'Sector', field: 'sector', lookup: this.state.sector },
      //     { title: 'Start Date', field: 'start_date', type: 'date' },
      //     { title: 'End Date', field: 'end_date', type: 'date' },
      // ]
      // this.setState({ empColumns: empColumn })
    })



  }

  fetchOps() {
    // this.setState({ isLoading: true })
    Service.get(prp_roles_api + "?is_archive=0&role=" + this.props.roleData.id, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        let data = [];
        let gangData = filterOneObjArrayByAnoterObjArray(res.data, this.props.gang, 'emp_id', 'ptd_employee')
        if (gangData) {
          data = gangData
        } else {
          data = []
        }
        let data2 = []
        data.forEach(ele => {

          let set = []
          let totalWeeks = 0;

          for (const item in ele.dates) {
            set.push(item)
            if (moment(item, 'DD/MM/YYYY').isValid()) {
              totalWeeks = Number(totalWeeks) + Number(ele.dates[item])
            }
          }
          ele['total_weeks'] = totalWeeks.toFixed(1)
          let sorted = set.sort(function (a, b) {
            a = a.toString().split('/');
            b = b.toString().split('/');
            return a[2] - b[2] || a[1] - b[1] || a[0] - b[0];
          });
          let allDatesQty = { ...ele, ...ele.dates }


          let st_en_date = { start_date: sorted[0], end_date: sorted[sorted.length - 1] }
          allDatesQty = { ...allDatesQty, ...st_en_date }
          // if (ele.shdl_data) {
          //     ele.shdl_data.forEach((dates, i) => {
          //         let alldates = weekStartDates(dates.from, dates.to);
          //         alldates.forEach(date => {
          //             let qty = dates.qty;
          //             allDatesQty = { ...allDatesQty, [date]: qty }
          //         })
          //     })
          // }
          data2.push(allDatesQty)
          // let keys = Object.keys(allDatesQty);
          // var last = keys[keys.length - 1];

        })

        this.setState({
          data: data2,
          dataLength: data2.length,
          // columns: this.props.column
        })
      })
      .catch(error => {

      })
      .finally(e => this.setState({ isLoading: false }))
  }


  handleCustomQty = (e, idx) => {

    let preObj = { ...this.state.customQtyValues[idx] }
    preObj['qty'] = parseInt(e.target.value);
    let newValues = [...this.state.customQtyValues];
    newValues[idx] = preObj;
    this.setState({ customQtyValues: newValues })
  }

  handleCustomToDate = (date, idx) => {
    let preObj = { ...this.state.customQtyValues[idx] }
    preObj['to'] = moment(date).toISOString();
    let newValues = [...this.state.customQtyValues];
    newValues[idx] = preObj;
    this.setState({ customQtyValues: newValues })
  }

  handleCustomFromDate = (date, idx) => {
    let preObj = { ...this.state.customQtyValues[idx] }
    preObj['from'] = moment(date).toISOString();
    let newValues = [...this.state.customQtyValues];
    newValues[idx] = preObj;
    this.setState({ customQtyValues: newValues })
  }

  applyCustomQty = (e) => {

    let empIdx = this.state.selectedEmployee.id;
    let allEmpData = [...this.state.data];

    let newData = { ...this.state.selectedEmployee };
    let allDatesQty = {};
    let continueToApply = true;
    this.state.customQtyValues.forEach((dates, i) => {
      let alldates = weekStartDates(dates.from, dates.to);

      alldates.forEach((date) => {
        let qty = Number(dates.qty);
        // if (!validQty.includes(qty)) {
        //     dates.error = true;
        //     dates.errorMsg = "Qty must be 0.2, 0.4, 0.5, 0.6, 0.8 or 1";
        //     // return false;
        //     continueToApply = false
        //     // allDatesQty = { ...allDatesQty, [date]: qty.toFixed(2) }
        // }

        // if (qty > 60) {
        //   dates.error = true;
        //   dates.errorMsg =
        //     "Maximun quantity limit is 60. Please add quantity below 60";
        //   continueToApply = false;
        // }

        if (date in allDatesQty) {
          qty += Number(allDatesQty[date]);
          // if (qty > 60) {
          //   dates.error = true;
          //   dates.errorMsg =
          //     "Qty value can not be more than 60/week for " + date;
          //   // return false;
          //   continueToApply = false;
          // }
          // allDatesQty = { ...allDatesQty, [date]: qty.toFixed(2) }
        }

        allDatesQty = { ...allDatesQty, [date]: qty.toFixed(1) };
      });
    });

    this.setState({ customQtyValues: this.state.customQtyValues });

    if (!continueToApply) return;

    Service.put(prp_roles_api + this.state.selectedEmployee.id + "/", {
      headers: {
        Authorization: "Token " + token,
      },
      data: {
        shdl_data: this.state.customQtyValues,
        employee: this.state.selectedEmployee.employee,
        dates: allDatesQty,
        type: this.state.type,
      },
    })
      .then((res) => {
        this.props.enqueueSnackbar("Qty added successfully", {
          variant: "success",
        });
        newData = { ...newData, ...allDatesQty };
        allEmpData[empIdx] = newData;
        this.props.getPRPtemplates(this.props.project['crm']['crm'])
        // this.setState({  }, () => )

        this.closeDialog();
      })
      .catch((error) => {
        console.log(error)
        this.props.enqueueSnackbar("Something went wrong!", {
          variant: "error",
        });
      })
      .finally((e) => {
        this.fetchOps();
      });

  };

  removeCustomQty = (idx) => {

    let data = [...this.state.customQtyValues];
    data.splice(idx, 1);

    this.setState({ customQtyValues: data })
  }



  postOps(data) {
    Service.post(prp_roles_api, {
      headers: {
        Authorization: "Token " + token,
      },
      data: data,
    })
      .then(res => {
        this.setState({ isLoading: false, })
        this.fetchOps();
        this.props.enqueueSnackbar('Resource added successfully', {
          variant: 'success'
        });
      })
      .catch(error => {
        this.setState({ isLoading: false })
        this.props.enqueueSnackbar('Something went wrong!', {
          variant: 'error'
        });
      })
  }


  putOps(data) {
    this.setState({ isLoading: true })
    // if (data.role === 58) { this.getGFgang(data.emp_id) }
    Service.put(prp_roles_api + data.id + "/", {
      headers: {
        Authorization: "Token " + token,
      },
      data,
    })
      .then(res => {
        this.addEmpDialogClose()
        this.fetchOps()
        // this.props.refreshRoles()
        this.props.enqueueSnackbar('Resource assigned to role successfully', {
          variant: 'success'
        });
      })
      .catch(error => {
        this.setState({ isLoading: false })
        // this.props.enqueueSnackbar('Something went wrong!', {
        //     variant: 'error'
        // });
        // this.fetchOps();
      });
  }

  archivePutOps(data) {
    Service.put(prp_roles_api + data.id + "/", {
      headers: {
        Authorization: "Token " + token,
      },
      data,
    })
      .then(res => {

        this.setState({
          archiveAlert: false,
          archive_emp_data: ''
        }, () => this.fetchOps())
        // this.props.refreshRoles()
        this.props.enqueueSnackbar('Employee archived successfully', {
          variant: 'success'
        });
      })
      .catch(error => {
        this.setState({ isLoading: false })
        this.props.enqueueSnackbar('Something went wrong!', {
          variant: 'error'
        });
        // this.fetchOps();
      });
  }

  deleteOps(data) {
    this.setState({ isLoading: true })
    Service.delete(prp_roles_api + data.id + "/", {
      headers: {
        Authorization: "Token " + token,
      }
    })
      .then(res => {
        this.fetchOps()
        this.props.enqueueSnackbar('Deleted successfully', {
          variant: 'success'
        });
      })
      .catch(error => {
        this.setState({ isLoading: false })
        // this.props.enqueueSnackbar('Something went wrong!', {
        //     variant: 'error'
        // });
        // this.fetchOps();
      });
  }
  tableCellRender(role, columnLabel) {
    // returns a lookup names
    if (columnLabel.field === "emp_id") {
      return columnLabel.lookup[role[columnLabel.field]];
    } else if (columnLabel.field === "type") {
      return columnLabel.lookup[role[columnLabel.field]];
    }
    return role[columnLabel.field];
  }

  deleteOrUnallocateSchedules(method) {
    Service.delete(`/api/role/schedule/delete_shdl_data/?id=` + this.state.deletedQty.id + '&method=' + method, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then((res) => {
        this.props.enqueueSnackbar('Schedule ' + method + " ed successfully", {
          variant: "success",
        });
        this.setState({ unallocateDeleteDialog: false });
        this.applySingleCustomQty()
      })
      .catch((error) => {
        console.log(error)
        this.setState({ unallocateDeleteDialog: false })
        this.props.enqueueSnackbar("Something went wrong!", {
          variant: "error",
        });
      })
  }

  render() {
    let searchParams = new URLSearchParams(window.location.search)


    let currentComponent = searchParams.get("dashboardcomp")
    if (currentComponent === 'Secured') {
      currentComponent = 'l6a_e'

    } else if (currentComponent === 'Unsecured') {
      currentComponent = 'l5a_e'

    }
    const allRoles = this.props.allRoles
    const roleId = this.props.roleData.role;
    const { classes } = this.props
    return (
      <>
        {this.state.isLoading ? <LoopCircleLoading color='#005D99' /> :
          <>
            <TableRow className={classes.rows}>
              {/* <TableCell style={{ paddingRight: "80px" }}>{""}</TableCell> */}
              <TableCell >
                {"Archive"}
              </TableCell>

              <TableCell className={classes.rows} style={{
                minWidth: "150px", width: "100px", zIndex: 99, position: "sticky",
                left: 0,
                background: "#F8F8F8",
                boxShadow: "1px 2px 1px blue"
              }}>
                {"Schedules"}
              </TableCell>
              {/* <TableCell style={{ padding: 45 }}>{""}</TableCell> */}

              <TableCell style={{ minWidth: "150px", width: "50px" }}>
                {"Assign Employee"}
              </TableCell>
              {/* <TableCell style={{ padding: 45 }}>{""}</TableCell> */}

              {this.props.columns.map((columnLabel, columnLabelIndex) => (
                <TableCell key={columnLabelIndex}
                  style={{ ...columnLabel.headerStyle, width: "100px" }}
                >
                  {columnLabel.title}
                </TableCell>
              ))}
            </TableRow>

            {this.state.data.map((row, i) => (
              <TableRow
                key={i}
                className={classes.rows}
                onClick={(e) => {
                  this.setState({ row, empTableOpen: true });
                }}
              >
                {/* <TableCell style={{ paddingRight: "100px" }}>{""}</TableCell> */}
                <TableCell>
                  <IconButton
                    aria-label="close"
                    onClick={(event) => {

                      this.setState({ archive_emp_data: row }, () => {
                        this.setState({ archiveAlert: true });
                      });
                    }}
                  >
                    <Tooltip title="Archive Employee">
                      <ArchiveOutlined />
                    </Tooltip>
                  </IconButton>
                </TableCell>
                <TableCell className={classes.leftSticky} style={{ minWidth: "150px" }}>
                  <IconButton
                    aria-label="Schedule Dates"
                    onClick={(event) => {
                      // Do save operation
                      if (row.shdl_data) {
                        this.setState({
                          customQtyValues: row.shdl_data,
                        });
                      }
                      this.setState({
                        selectedRole: this.props.roleData,
                        selectedEmployee: row,
                      });
                      this.openDialog();
                    }}
                  >
                    <Tooltip title="Schedule Dates" placement="left-start">
                      <TimelineIcon />
                    </Tooltip>
                  </IconButton>
                </TableCell>
                {/* <TableCell style={{ padding: 45 }}>{""}</TableCell> */}
                <TableCell style={{ minWidth: "150px" }}>
                  <IconButton
                    aria-label="close"
                    onClick={(event) => {

                      this.setState({ assign_emp_data: row }, () => {
                        this.addEmpDialogOpen();
                      });
                    }}
                  >
                    <Tooltip title="Assign Employee" placement="left-start">
                      <PersonAddIcon />
                    </Tooltip>
                  </IconButton>
                </TableCell>

                {/* <TableCell style={{ padding: 45 }}>{""}</TableCell> */}
                {this.props.columns.map((columnLabel, indx) => (
                  <TableCell
                    key={indx}
                    style={{ ...columnLabel.headerStyle, width: "100px" }}
                  >
                    {this.tableCellRender(row, columnLabel)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            <TableRow className={classes.rows} >
              <TableCell style={{ width: "100%" }} className={classes.leftSticky}>
                <Tooltip title="Add Resource" placement="left-start">
                  <IconButton
                    aria-label="Add User"
                    onClick={(event) => {
                      this.postOps({
                        crm: this.props.project['crm']['crm'],
                        prp: this.props.roleData.id,
                      });
                    }}
                  >
                    <GroupAddIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </>
        }

        <Dialog open={this.state.showDialog} onClose={this.closeDialog} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            Add Qty
            <IconButton aria-label="close" onClick={this.addCustomField} style={{ position: 'absolute', top: '5px', right: '20px' }}>
              <AddIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {this.state.selectedEmployee.employee}&nbsp;({allRoles[roleId]})


              <FormControl variant="outlined" className={classes.formControl} size="small">
                <InputLabel>Type</InputLabel>
                <Select
                  id="rolesToPost"
                  name="rolesToPost"
                  value={Number(this.state.selectedEmployee.type)}
                  onChange={(e) => {
                    this.setState({
                      type: e.target.value,
                      selectedEmployee: { ...this.state.selectedEmployee, type: e.target.value }
                    })
                  }}
                  label="Select Type"
                >
                  <MenuItem value={0}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={1}>
                    Project
                  </MenuItem>
                  <MenuItem value={2}>
                    Tender
                  </MenuItem>

                </Select>
              </FormControl>
            </DialogContentText>
            {this.state.customQtyValues.map((ele, i) => (
              <Grid container spacing={3}>
                <Grid item xs={2} sm={2}>
                  {/* <IconButton aria-label="remove"
                    style={{ marginTop: '10px' }}
                    // onClick={() => this.removeCustomQty(i)}
                    onClick={() => this.setState({ deletedQty: ele, unallocateDeleteDialog: true })}
                    disabled={this.state.customQtyValues.length === 1}>
                    <RemoveIcom />
                  </IconButton> */}
                  <PopupState variant="popover" popupId="demo-popup-popover">
                                            {(popupState) => (
                                                <div>
                                                    <IconButton aria-label="remove"
                                                        style={{ marginTop: '10px' }}  {...bindTrigger(popupState)}
                                                        // onClick={() => this.removeSingleCustomQty(i)}
                                                        // onClick={() => this.setState({ deletedQty: element, unallocateDeleteDialog: true })}
                                                        disabled={this.state?.singleCustomQtyValues.length === 1}
                                                    >
                                                        <RemoveIcom />
                                                    </IconButton>
                                                    <Popover
                                                        {...bindPopover(popupState)}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'center',
                                                        }}
                                                        onClick={() => this.setState({ deletedQty: ele, unallocateDeleteDialog: true })}
                                                    >
                                                        <MenuItem value={'unallocate'}>Unallocate</MenuItem>
                                                        <MenuItem value={'delete'}>Delete</MenuItem>
                                                    </Popover>
                                                </div>
                                            )}
                                        </PopupState>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      format="dd/MM/yyyy"
                      margin="normal"
                      id={'from'}
                      label="From"
                      // minDate={this.props.project.contractor_start_date}
                      // maxDate={this.props.project.contractor_end_date}
                      value={ele.from ? ele.from : undefined}
                      onChange={(e) => this.handleCustomFromDate(e, i)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      className={classes.date}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      format="dd/MM/yyyy"
                      margin="normal"
                      id={'to'}
                      label="To"
                      // minDate={this.props.project.contractor_start_date}
                      // maxDate={this.props.project.contractor_end_date}
                      value={ele.to ? ele.to : undefined}
                      onChange={(e) => this.handleCustomToDate(e, i)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      className={classes.date}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    id={'qty'}
                    label="Qty/Week"
                    type="number"
                    fullWidth
                    margin="dense"
                    value={ele.qty}
                    onChange={(e) => this.handleCustomQty(e, i)}
                  />
                </Grid>
                {ele.error === true ?
                  <p style={{ marginTop: '0', marginLeft: '100px', color: '#F44336' }}
                  >{ele.errorMsg}</p> : null}
              </Grid>

            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="secondary">
              Cancel
            </Button>
            <Button color="primary" onClick={(e) => this.applyCustomQty(e)}>
              Apply
            </Button>
          </DialogActions>
        </Dialog>



        <Dialog open={this.state.addEmpDialog} onClose={this.addEmpDialogClose} maxWidth={"lg"}>
          <DialogTitle id="form-dialog-title">
            Add Employee
            <IconButton aria-label="close" onClick={this.addEmpDialogClose} style={{ position: 'absolute', top: '5px', right: '20px' }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ padding: 0 }}>

            <MaterialTable
              title="Employees"
              columns={[
                { title: 'Employee No', field: 'emp_no', },
                { title: 'First Name', field: 'first_name', },
                { title: 'Last Name', field: 'last_name', },
                { title: 'Email Address', field: 'email', },
                { title: 'Role', field: 'job_title', lookup: this.props.allRoles },
                { title: 'Sub Division', field: 'owner_region', lookup: this.state.owner_region },
                { title: 'Operations Lead', field: 'owner_delivery_unit', lookup: this.state.owner_delivery_unit },
                { title: 'Sector', field: 'sector', lookup: this.state.sector },
                { title: 'Start Date', field: 'start_date', type: 'date' },
                { title: 'End Date', field: 'end_date', type: 'date' },
              ]}
              data={this.state.gangRoleEmployees}
              isLoading={this.state.isLoading}
              options={{
                headerStyle: {
                  fontWeight: 'bold',
                },
                padding: 'dense',
                showTitle: false,
                searchFieldAlignment: 'left',
                paging: false,
                pageSize: 20
              }}
              onRowClick={(event, rowData, togglePanel) => {

                this.putOps({
                  emp_id: rowData.id,
                  crm: this.props.project['crm']['crm'],
                  prp: this.props.roleData.id,
                  role: this.props.roleData.role,
                  id: this.state.assign_emp_data.id
                })
              }}
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={this.addEmpDialogClose} color="primary">Cancel</Button>
            {/* <Button color="primary" onClick={this.postOps}>Add</Button> */}
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.archiveAlert} onClose={() => this.setState({ archiveAlert: false })} maxWidth={"lg"}>
          <DialogTitle id="form-dialog-title">
            Archive Employees
            <IconButton aria-label="close" onClick={() => this.setState({ archiveAlert: false })} style={{ position: 'absolute', top: '5px', right: '20px' }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ padding: 15 }}>

            <Typography style={{ padding: 10 }}>Are you sure to archive this Employee</Typography>

          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => this.setState({ archiveAlert: false })}>Cancel</Button>
            <Button color="primary" onClick={() => this.archivePutOps({
              id: this.state.archive_emp_data.id,
              is_archive: 1,
            })}>Archive</Button>
            {/* {this.props.deletePRPAccess[currentComponent] ? <Button color="primary" onClick={() => this.deleteOps({
              id: this.state.archive_emp_data.id,
            })}>Delete</Button> : null} */}
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.unallocateDeleteDialog} onClose={() => this.setState({ unallocateDeleteDialog: false })}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>Do you want the following schedule to be unallocated or deleted?</DialogContent>
          <DialogActions>
            <Button color='primary' size='small' variant='contained' onClick={() => this.deleteOrUnallocateSchedules('delete')}>Delete</Button>
            <Button color='primary' size='small' variant='contained' onClick={() => this.deleteOrUnallocateSchedules('unallocate')}>Unallocate</Button>
          </DialogActions>
        </Dialog>

      </>
    )
  }
}

export default withSnackbar(withStyles(useStyle)(PRPEmpTable))